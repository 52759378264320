@import "variables";


//============================logincomponents.scss============================//

@mixin page-content-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin image-back-ground {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right; 
}

//============================accountPage.scss============================//

@mixin informationContainer {
    /*Display*/
    display:flex;
    align-items:center;
    flex-direction: column;
    width: auto;

    /*Color*/
    color:$LightColor;
}

@mixin buttonStyle {
    /*Display*/
    width: 305px; 
    height: 3em;
    margin-top: 10px;
    border-radius: 8px;

    /*Color*/
    color: $LightColor;

    /*Text*/
    font-size: 14px;
}

@mixin account-page-header-icons {
    /*Display*/
   position: absolute;
   margin-left:45px ; 
   margin-right:45px ;
   margin-top: 25px ;

   /*Color*/
   color: $LightColor;

   /*Text*/
   font-size: 42px
}

//============================bottomNavbar.scss============================//

@mixin navigationSideButtons {
    /*Positioning*/
    position: absolute;
    z-index: 3;
    bottom: 25px; 

    /*Display*/
    border-radius: 50%;
    padding: 15px;

    /*Color*/
    background-color:$DarkColor; 
}