.logo {
    width: 200px;
  
    svg.st1 {
      fill: #4D8C53;
    }
  
    svg.st2 {
      fill: #FFAE46;
    }
  }
  
  .navbar-brand, .loginPageContent {
    .logo {
      top: 8px;
      width: 90px;
      position: absolute;
    }
  }