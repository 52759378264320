body {
    font-family: "Poppins", sans-serif !important;
  }
  .bg-dark {
    background-color: $DarkColor !important;
  }
  
  .nav-link {
    font-size: 1.4em;
    font-weight: 700;
  }