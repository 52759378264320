/*Positioning*/
/*Display*/
/*Color*/
/*Text*/
/*Other*/

//============================Login Components============================//

.backgroundImage{
    @include page-content-container;
    @include image-back-ground;
}

.loginPageContent {
    @include page-content-container;
    /*Display*/
    flex-direction: column;
}

.registerCodeInputFields {
    /*Display*/
    width: 35px;
    height: 50px; 
    margin: 10px ;
    border-radius: 8px;
    margin-bottom: 25px;

    /*Text*/
    text-align: center;
}

.registerText {
    /*Display*/
    margin-top: 10px;
    margin-bottom: 5px;

    /*Color*/
    color: $LightColor;
}

//============================OPTION SIDEBAR.scss============================//

.sidebarCloseButton {
    /*Display*/
    margin-right:10px ;
    font-size:36px;
    margin-top:15px ;
    z-index: 3;

    /*Color*/
    color:$LightColor; 
}

.sidebarUsernameContainer {
    /*Display*/
    display:flex;
    justify-content:flex-start;
    margin-left:45px;
}

.sidebarUserIconContainer {
    /*Display*/
    border-radius: 50%;
    padding: 10px;

    /*Color*/
    background-color:$NeutralColor; 
}

.sidebarUserIcon {
    /*Display*/
    width:36px; 
    height:36px
}

.sidebarUsernameColumn {
    /*Display*/
    display:flex;
    flex-direction:column;
    margin-left:25px ;
    width: 150px;

    /*Text*/
    text-align: left; 
}

.sidebarMechanicInfoContainer {
    /*Display*/
    padding-top:15px; 
    padding-bottom:15px; 
    margin-top:25px;
    margin-bottom: 10px;
    width: 100%;

    /*Color*/
    background-color:$LightColor; 
    color:$DarkColor;
    
    /*Text*/
    text-align: center;
}

.sidebarActiveInfoContainer {
    /*Display*/
    display:flex;
    justify-content:flex-start;
    margin-left:45px ;
}

.sidebarMechanicIconContainer {
    /*Display*/
    border-radius: 50%;
    padding: 10px; 
    justify-content:center;
    align-content:center;
    margin-top: 10px;

    /*Color*/
    background-color:$NeutralColor;  
}

.sidebarMechanicIcon {
    /*Display*/
    width:36px; 
    height:36px
}

.sidebarActiveMechanicInfoContainer {
    /*Display*/
    display:flex;
    flex-direction:column;
    margin-left:15px ;
    justify-content:center;
    align-content:flex-start;
    width: 100%;

    /*Text*/
    text-align: left; 
}

.sidebarActiveMechanicText {
    /*Text*/
    font-size: 16px;
}

.sidebarNonActiveMechanicText {
    /*Text*/
    font-size: 24px;
}

.sidebarSocialMediaIcons {
    /*Display*/
    width:36px;
    height:36px; 
    margin-left:30px ; 
    margin-right:30px ;
}

.desktopSidebarUsernameContainer {
    /*Display*/
    display:flex;
    justify-content:flex-start;
    margin-top:45px;
}

.desktopSidebarCloseButton {
    /*Display*/
    position:absolute; 
    top:0;
    right:0;
    margin-right:10px ;
    font-size:36px;
    margin-top:15px ;

    /*Color*/
    color:$LightColor; 
}

.desktopOptionsText {
    font-size: 25px;
    text-decoration: none;
    margin-top: 5px;
}

//============================ACCOUNT PAGE.scss============================//

.accountPageContainer {
    /*Display*/
    @include page-content-container;
    @include image-back-ground;
    flex-direction: column; 
}

.accountPageContent {
    /*Display*/
    @include page-content-container;
    flex-direction: column; 
    // margin-top: 20px 
}

.headerIconLeft {
    @include account-page-header-icons;
    top: 10px;
    left: 10px;
}

.userInformation {
    @include informationContainer;
}

.accountUserInformationContainer {
    width: 300px;
    /*Text*/
    text-align: left;
    /*Color*/
    color:$LightColor;
}

.iconAndUsernameField {
    /*Display*/
    align-items: center;
    justify-content: flex-start;
    display:flex; 
    width: 300px;

    /*Color*/
    color:$LightColor;
}

.accountUserIcon {
    /*Display*/
    border-radius: 50%;
    width:52px;
    height:52px;
    background-color:$NeutralColor;
}

.userNameContainer {
    /*Display*/
    display:flex;
    flex-direction:column;
    margin-left:25px ;
    width: 150px;

    /*Text*/
    text-align: left;
}

.accountUserInfoBottomText {
    /*Display*/
    margin-top:15px ;

    /*Color*/
    color:$PrimaryColor;

    /*Text*/
    font-size:16px;
}

.accountLinkText {
    /*Display*/
    cursor: pointer;
    /*Color*/
    color: $LightColor;

    /*Text*/
    font-size: 14px;
    text-decoration: underline;
}

.accountUserName {
    /*Text*/
    font-size:20px;
}

.accountUserSubName {
    /*Text*/
    font-size:12px;
}

.bikeInformationContainer {
    /*Display*/
    display:flex;
    width: 300px;
}

.accountBikeInfoSubText {
    /*Display*/
    margin-top:15px ;

    /*Text*/
    text-align: right;
    font-size:12px; 
}

.accountBikeInfoText {
    /*Display*/
    margin-top:2px ;

    /*Text*/
    text-align: right;
    font-size:16px;
}

.accountUserInfoText {
    /*Display*/
    margin-top:2px ; 

    /*Text*/
    font-size:16px;
}

.accountUserInfoSubText {
    /*Display*/
    margin-top: 15px;

    /*Text*/
    font-size: 12px;
}


.accountPageButtons1 {
    @include buttonStyle;

    /*Color*/
    background-color: $ThirdColor;
}

.accountPageButtons2 {
    @include buttonStyle;

    /*Display*/
    margin-bottom: 15px;

    /*Color*/
    background-color: $PrimaryColor;
}

.brandInformationContainer,
.modelInformationContainer {
    /*Display*/
    flex:1;
    white-space: nowrap;

    /*Text*/
    &.modelInformationContainer {
        text-align: right;
    }
}

//============================BOTTOM NAVBAR.scss============================//

.bottomNavBarAccountButtonContainer {
    @include navigationSideButtons;

    /*Positioning*/
    left: 1%;
    transform: translateX(-1%);
}

.bottomNavBarMessageButtonContainer {
    @include navigationSideButtons;

    /*Positioning*/
    left: 99%;
    transform: translateX(-99%);
}

.bottomNavBarNavigateButton {
    /*Color*/
    color: $LightColor;
    
    /*Text*/
    font-size: 42px;
}

//============================MAP.scss============================//

.simulateEndOfServiceButton {
    /*Positioning*/
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /*Display*/
    border-radius: 10px;
    padding: 10px;
    width: 280px;
    height: 50px;

    /*Color*/
    color: $LightColor;
    background-color: $DarkColor;

    /*Text*/
    font-size: 14px;
}

//============================MODAL.scss============================//

.modalHeader {
    /*Display*/
    display:flex 
}

.modalConfirmButton {
    /*Display*/
    border-radius: 8px;
    width: 250px;
    height: 40px;

    /*Color*/
    background-color:$DarkColor;
    color: $LightColor;
}

.modalFooter {
    /*Display*/
    align-items:center !important; 
    justify-content:center !important  
}

.modalHearts {
    /*Display*/
    margin-right:5px 
}

.modalBodyRows{
    /*Display*/
    width:100% 
}

.modalCloseButton {
    cursor: pointer;

    /*Text*/
    font-size:36px
}

.modalBody {
    /*Display*/
    display:flex; 
    width:100%
}

.endServiceModalTitleContainer {
    /*Display*/
    flex:2;
    margin-top: 2px;

    /*Text*/
    text-align: left;
}

.endServiceModalCloseContainer {
    /*Display*/
    flex:1;

    /*Text*/
    text-align: right
}

.endServiceModalBodyContainers { 
    /*Text*/
    text-align: center
}

.confirmModalNameContainer,
.confirmModalBodyLeftColumn {
    /*Display*/
    flex:1;

    /*Text*/
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.confirmModalCloseContainer,
.confirmModalBodyRightColumn{
    /*Display*/
    flex:1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    

    /*Text*/
    text-align: right
}

//============================ORDER DETAILS PANEL.scss============================//

.orderDetailsExpandButton {
    /*Display*/
    width:90px; 
    height:5px; 
    border-radius:15px; 
    margin-bottom:15px; 
    margin-top:15px;

    /*Color*/
    background-color:$DarkColor; 
}

.orderDetailsMechanicText {
    /*Text*/
    font-size: 18px; 
}

.orderDetailsSubText {
    /*Text*/
    font-size: 14px; 
    text-align: left;
}

.orderDetailsTime {
    /*Display*/
    margin-top: 2px;

    /*Text*/
    font-size: 14px; 
    text-align: right 
}

.orderDetailsCards {
    /*Display*/
    border-radius: 15px;
    width:80px;
    height:80px;
    margin-right:5px;
    margin-left:5px;

    /*Color*/
    background-color:$NeutralColor;

    /*Text*/
    font-size: 12px;
}

.orderDetailsCardsContainer {
    margin-bottom: 10px;
}

//============================NAVBAR.scss============================//

.navbar-collapse-bg {
    background-color: transparent; /* Default to transparent for larger screens */
}

@media (max-width: 768px) {
    .navbar-collapse-bg {
        background-color: rgba(0, 0, 0, 0.5); /* Apply background color on small screens */
    }
}


.herooverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
    z-index: 1; /* Ensure the overlay is on top of other elements */
  }
  
  .hero-title {
    color: white;
    font-size: 5em;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .hero-subtitle {
    font-size: 1.5em;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease-in-out 0.5s;
  }
  
  .hero-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2em;
    text-decoration: none;
    color: #fff;
    background-color: #3a608d;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .hero-button:hover {
    background-color: #e07160;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }





  /* parallax */
@media only screen and (max-width: 768px) {
    section.page-header-parallax,
    section.parallax {
        background-attachment: fixed !important;
        background-size: auto 150% !important;
        background-position: 50% -50px !important;
        background-attachment: scroll !important;
    }

    section.parallax-xs-fixed {
        background-attachment: scroll !important;
        background-size: 200% !important;
        background-position: 50% -50px !important;
    }
}

.parallax h1,
.parallax h2,
.parallax h3,
.parallax h4,
.parallax h5,
.parallax h6,
.parallax p,
.parallax .btn,
.parallax img,
.parallax div,
.parallax {
    color: #fff;
    position: relative;
    z-index: 10;
}
.parallax .btn-default {
    background-color: transparent;
}
.parallax .overlay {
    background-color: rgba(34, 34, 34, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.parallax h1 {
    font-size: 70px;
}
.parallax h2 {
    font-size: 60px;
}
.parallax h3 {
    font-size: 50px;
}
@media only screen and (max-width: 760px) {
    /*
		.parallax {
			background-position:center center !important;
		}
		*/
    .parallax h1 {
        font-size: 55px;
    }
    .parallax h2 {
        font-size: 45px;
    }
    .parallax h3 {
        font-size: 45px;
    }
}
@media only screen and (max-width: 500px) {
    .parallax h1 {
        font-size: 36px;
    }
    .parallax h2 {
        font-size: 30px;
    }
    .parallax h3 {
        font-size: 30px;
    }
}

.parallax .overlay {
    background-color: rgba(34, 34, 34, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.overlay.dark-5 {
    background-color: rgba(0, 0, 0, 0.5);
}

input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    margin: 0 0.5rem 0 0;
    font: inherit;
    width: 1.15em;
    height: 1.15em;
    border: 2px solid #4D8C53; /* Border color */
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
  }

/* Checked state with custom color */
input[type="radio"]:checked {
    background-color: #4D8C53; /* Fill color when selected */
    border-color: black;
  }

/* Circle indicator inside the checked radio */
input[type="radio"]:checked::before {
    content: "";
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white; /* Color of the inner circle */
  }



.modal-content{
    background-color: rgba(0, 0, 0, 0.9) !important
}





// .video-frame iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100% !important;
//     height: 100% !important;
//   }
  
.video-responsive {
    width:80%;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    position: absolute;
    left: 50%;  /* Start at 50% of the parent's width */
    top: 50%;   /* Start at 50% of the parent's height */
    transform: translate(-50%, -50%); /* Offset by half its width and height */
    height: 90%; /* Adjust height as needed */
    width: 100%; /* Adjust width as needed */
}