.tech-position {
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.6);
    width: 100%;
    bottom: 0;
    margin-top: 65px;
    z-index: 1;
  }
  
  .fullscreen-map {
    height: 100%;
    width: 100%;
  }
  .map {
    z-index: 0;
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    max-height: 100%;
    overflow-y: hidden;
    background-color: $NeutralColor !important;
  
    div:first-of-type {
      overflow: inherit !important;
      height: inherit !important;
    }
  
    .bottom-nav {
      position: absolute;
      bottom: 0;
  
    .help {
      position: absolute;
      border-radius: 100%;
      background-color: $PrimaryColor;
      width: 140px;
      height: 140px;
      min-width: 140px;
      min-height: 140px;
      max-width: 140px;
      max-height: 140px;
      border: 0;
      transform: scale(1.0, 1.0);
      box-shadow: 0 9px 12px -9px lighten($DarkColor, 15);
      transition: all 0.2s ease-in-out;
  
      img {
        width: 40px;
        height: auto;
      }
  
      &:hover {
        transform: scale(0.95, 0.95);
        box-shadow: 0 6px 6px -2px lighten($DarkColor, 15);
        transition: all 0.2s ease-in-out;
      }
  
      &:after {
        content: "";
        top: 5px;
        left: 5px;
        position: absolute;
        border-radius: 100%;
        width: 130px;
        height: 130px;
        min-width: 130px;
        min-height: 130px;
        max-width: 130px;
        max-height: 130px;
        border: 2px solid $LightColor;
      }
  
      &.active {
        background-color: $AccentColor;
        transform: scale(0.7, 0.7);
        box-shadow: 0 6px 6px -2px lighten($DarkColor, 15);
        transition: all 0.2s ease-in-out;
  
        &:before {
          content: "CANCEL SERVICE";
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: $LightColor;
          top: 20px;
          left: 20px;
          position: absolute;
          border-radius: 100%;
          width: 100px;
          height: 100px;
          min-width: 100px;
          min-height: 100px;
          max-width: 100px;
          max-height: 100px;
          background-color: $AccentColor;
        }
      }
    }
  
    .message {
      border-radius: 100%;
      background-color: $PrimaryColor;
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      max-width: 100px;
      max-height: 100px;
  
      img {
        width: 30px;
        height: auto;
      }
    }
  }
  }

  .simulateEndOfServiceButton {
    /*Positioning*/
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /*Display*/
    border-radius: 10px;
    padding: 10px;
    width: 275px;
    height: 45px;

    /*Color*/
    color: $LightColor;
    background-color: $DarkColor;

    /*Text*/
    font-size: 14px;
}